body {
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-attachment: fixed !important;
  background-position: top center !important;
  min-height: -webkit-fill-available !important;
  height: 100vh !important;
  background-color: rgb(243, 243, 243);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* unvisited link */
a:link {
  color: #385773;
}

/* visited link */
a:visited {
  color: #385773;
}

/* mouse over link */
a:hover {
  color: #385773;
}

/* selected link */
a:active {
  color: #385773;
}

.html2canvas-container { width: 3000px !important; height: 3000px !important; }

/* chatbot styling */
.app-chatbot-container {
  margin: 40px 0;
  display: flex;
  justify-content: center;
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 9999;
  box-shadow: 5px 5px 13px rgba(91, 81, 81, 0.4);
  border-radius: 5px;
}

.app-chatbot-button {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: #1f3646;
  border: none;
  position: fixed;
  bottom: 15px;
  z-index: 9999;
  right: 40px;
}

.app-chatbot-button-icon {
  width: 25px;
  height: 25px;
  fill: #fff;
}

@media (max-width: 600px) {
  .app-chatbot-container {
    transform: scale(0.9) translateY(26px);
  }
}
